<template>
  <div>
    <div class="widgetContainer widgetContainer--center widgetContainer--scrollable bg-white ownersList">
      <div class="navigationHeader">
        <div class="navigationHeader__inner">
          <span
            class="icon-Arrow-big-left cancel"
            @click="goToPreviousScreen" />
          <p class="title">
            {{ $t('ownerList_NavTitle') }}
          </p>
        </div>
      </div>

      <div class="widgetContainer__body">
        <div class="wise-form">
          <p class="wise-form__header">
            {{ $t('ownerList_HeaderTitle') }}
          </p>
          <div
            class="listSimplev2 ownersList__greater-than"
            v-if="owners && owners.length">
            <ul>
              <li v-for="o in owners" :key="o.id">
                <p class="label">
                  {{ o.person.firstName }} {{ o.person.lastName }}
                </p>
                <span class="value">{{ o.ownership }}%</span>
              </li>
            </ul>
          </div>

          <div v-if="totalOwnerShip <= 75">
            <p class="wise-form__header">
              {{ $t('ownerList_AddNew_HeaderTitle') }}
            </p>
            <div class="listSimpleArrows no-status" @click="addOwner">
              <li>
                <p>{{ $t('ownerList_AddNew_SubTitle') }}</p>
                <span class="icon-Arrow-small-right" />
              </li>
            </div>
          </div>

          <p class="ownersList__verify-description wise-form__info">
            {{ $t('ownerList_Desc') }}
          </p>
        </div>
        <div class="widgetContainer__footer--fixed">
          <el-button type="primary" @click="proceedToSignIn" class="el-button__brand brand">
            {{ $t('ownerList_NextButton') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import businessMixin from '../../mixins/business';
export default {
  name: 'OwnersList',
  mixins: [businessMixin],
  data() {
    return {
      owners: []
    };
  },
  computed: {
    totalOwnerShip() {
      let total = 0;
      this.owners.forEach((o) => {
        total += parseInt(o.ownership);
      });
      return total;
    }
  },
  methods: {
    ...mapActions('kyb', ['fetchAllOwners', 'updateOwner']),
    ...mapMutations('kyb', ['setCreatePageOwnerDetails']),
    ...mapMutations('account', ['updateCreateAccountData']),
    afterBusiness() {
      this.getAllOwners(this.getSelectedBusiness.id);
    },
    getAllOwners(businessId) {
      const loader = this.showLoader();
      this.fetchAllOwners(businessId)
        .then((res) => {
          if (res.data.length !== 0) {
            /** @type {import('@m/kyb/store/state').kybState['owners']}*/
            this.owners = res.data;
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    addOwner() {
      this.setCreatePageOwnerDetails(null);
      this.$router.push('/kyb/ownerDetails');
    },
    proceedToSignIn() {
      if(this.isHellosignEnabled)
        this.$router.push('/kyb/helloSign');
      else
        this.proceedToAccountCreation();
    },
    proceedToAccountCreation() {
      
      this.updateCreateAccountData({
        businessId: this.getSelectedBusiness.id,
        type: 'businessChecking'
      });
      this.$router.push('/account/setup');
    },
  }
};
</script>

<style lang="scss">
.ownersList {

  &__greater-than {
    ul {
      padding: 0 16px;

      li {
        padding: 0;
        border-bottom: 1px solid var(--branding-sidebar-border);;
        margin: 0;

        &:last-of-type {
          border: none;
        }
      }
    }
  }

  .wise-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__owner-list {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 16px;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 25px;

    li {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  &__add-owner {
    border-radius: 10px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 11px;

    .info {
      padding-left: 15px;

      .title {
        font-size: 15px;
        line-height: 20px;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
        color: rgba(#3c3c43, 0.6);
      }
    }

    .icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background-color: var(--branding);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      span {
        color: #fff;
      }
    }

    .icon-arrow-right {
      margin-left: auto;
      color: #c7c7c9;
      font-size: 13px;
    }
  }

  &__verify-description {
    padding-bottom: 60px;
    margin-top: auto;
  }

  .el-input-number.is-without-controls .el-input__inner {
    text-align: left;
  }
}
</style>
